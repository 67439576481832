import { IDocumentReference } from './document-reference'
import { IEmailNamePair } from './email-name-pair.interface'
import { IUser } from './user'

export enum PaymentMethod {
  CreditCard = 'CREDIT_CARD',
  BankTransfer = 'BANK_TRANSFER',
  FixPay = 'FIX_PAY',
  RetribuicionFlexible = 'RETRIBUICION_FLEXIBLE',
}

export enum PaymentType {
  RepaymentToEP = 'REPAYMENT_TO_EP',
  FixPayInstalment = 'FIXPAY_INSTALMENT',
  Upfront = 'UPFRONT',
  LearningBudget = 'LEARNING_BUDGET',
}

export interface IPayment {
  _id?: string
  user: IUser | string
  totalAmount: number
  currency: string
  agreementFeeAmount: number
  status: PaymentStatus
  externalPaymentId?: string
  paymentMethod: PaymentMethod
  paymentSources: IDocumentReference[]
  automaticPayout: boolean
  transferred: boolean
  paymentType: PaymentType
  scheduledTo?: Date
  payer?: string
}

export interface IPaymentCheckoutResponse {
  redirectUrl?: string
  applicationId?: string
  orderId?: string
}

export enum PaymentStatus {
  Authorized = 'AUTHORIZED',
  Pending = 'PENDING',
  Paid = 'PAID',
  Failed = 'FAILED',
}

export enum PaymentFeature {
  LearningBudget = 'LEARNING-BUDGET',
  TaxBenefits = 'TAX-BENEFITS',
}

export enum CheckoutType {
  UpFront = 'UPFRONT',
  Fixpay = 'FIXPAY',
  Free = 'FREE',
  Isa = 'ISA',
  NoAgreement = 'NO_AGREEMENT',
  Fundae = 'FUNDAE',
}

export interface ICheckoutPayload {
  courseId: string
  cohortId: string
  type?: CheckoutType
  learningBudget?: {
    amount: number
    currency: string
  } | null
  fixedNumberOfInstalments?: number
  flexibleRemuneration?: boolean
  reviewerEmails?: IEmailNamePair[]
}

export interface IFixPayCheckoutPayload {
  financialProductId: string
  successUrl: string
  cancelUrl: string
}

export enum PaymentOption {
  UpFront = 'UPFRONT',
  FixPay = 'FIX_PAY',
  ISA = 'ISA',
  LearningBudgetInFull = 'LEARNING_BUDGET_IN_FULL',
  Fundae = 'FUNDAE',
  Klarna = 'KLARNA',
}

export enum NewPaymentOption {
  Card = 'card',
  Klarna = 'klarna',
  FixPay = 'fix_pay',
  Isa = 'isa',
  LearningBudget = 'learning_budget',
  TaxBenefits = 'tax_benefits',
  Fundae = 'fundae',
  Free = 'free',
  NoAgreement = 'no_agreement',
}

export enum PaymentFeature {
  CustomizableLearningBudget = 'CUSTOMIZABLE_LEARNING_BUDGET',
  FlexibleRemuneration = 'FLEXIBLE_REMUNERATION',
}
