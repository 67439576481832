import {
  Description,
  Dialog,
  Transition,
  TransitionRootProps,
} from '@headlessui/react'
import { Fragment } from 'react'

import { FragmentStyleContainer } from '@/app/components/FragmentStyleContainer'
import { CloseFilled } from '@/app/icons/CloseFilled'

export type ModalProps = React.PropsWithChildren<
  React.HTMLAttributes<HTMLDivElement> & {
    isOpen: boolean
    title: React.ReactNode
    subtitle?: React.ReactNode
    onClose: () => void
    noCloseButton?: boolean
    transitionProps?: TransitionRootProps
  }
>

export default function Modal({
  isOpen,
  onClose,
  title,
  subtitle,
  children,
  noCloseButton,
  transitionProps = {},
  ...props
}: ModalProps) {
  return (
    <Transition
      appear
      show={isOpen}
      as={Fragment}
      {...(transitionProps as unknown as Record<any, unknown>)}
    >
      <Dialog
        as="div"
        className={`relative z-50 font-sans ${props.className ?? ''} `}
        onClose={onClose}
      >
        <FragmentStyleContainer>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-modal-bg" />
          </Transition.Child>

          <div className="overflow-y-auto fixed inset-0 bg-modal-bg">
            <div className="flex justify-center items-end min-h-full md:items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`relative w-full overflow-hidden overflow-y-auto text-left align-middle transition-all transform bg-white shadow-xl md:rounded-2xl md:w-fit ${props.className ?? ''}`}
                >
                  <header className="flex flex-col py-8 sticky top-0 bg-white z-10">
                    <Dialog.Title className="flex flex-row md:gap-48 px-8 text-2xl">
                      <div className="break-words">{title}</div>
                      {!noCloseButton && (
                        <button
                          className="self-start p-1 ml-auto rounded-lg shrink-0"
                          onClick={onClose}
                          tabIndex={4}
                        >
                          {<CloseFilled className="w-5 h-5 text-mydra-black" />}
                        </button>
                      )}
                    </Dialog.Title>
                    {subtitle && (
                      <Description className="px-8 text-sm text-[--text]">
                        {subtitle}
                      </Description>
                    )}
                  </header>
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </FragmentStyleContainer>
      </Dialog>
    </Transition>
  )
}
